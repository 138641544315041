import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import URL_UTILITY from '../../../utility/url.utility';
export default {
  name: 'awsBackupReports',
  components: {
    DatePicker,
    ModelSelect
  },
  watch: {
    currentPage: function() {
      this.awsBackupReport();
    },
    perPage: function() {
      this.currentPage = 1;
      this.awsBackupReport();
    }
  },
  data() {
    return {
      requestId: null,
      loader: false,
      reqName: null,
      moduleName: null,
      tableName: null,
      backupDate: null,
      startDate: null,
      endDate: null,
      selectedStatus: {},
      statusType: [
        {
          value: null,
          text: null
        }
      ],
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      downloadLog: URL_UTILITY.getDownloadLOGUrl,
      totalRows: null,
      currentPage: 1,
      awsBackupReportData: [],
      awsBackupReportFields: [
        { key: 'request_id' },
        { key: 'request_name' },
        { key: 'module', class: 'text-fix' },
        { key: 'table_name'},
        { key: 'backup_date' },
        { key: 'start_date' },
        { key: 'end_date' },
        { key: 'status' },
        { key: 'parameter' },
        { key: 'LOG' }
      ],
      unsubscribe: null,
      totalPayload: null
    };
  },
  mounted() {
    this.getRequestStatus();
    this.awsBackupReport();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.totalPayload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'template/awsBackupReport',
            'aws-backup-repoprt',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    awsBackupReport() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        request_id: this.requestId,
        request_name: this.reqName,
        module_name: this.moduleName,
        table_name: this.tableName,
        backup_date: this.backupDate,
        start_date: this.startDate,
        end_date: this.endDate,
        status: this.selectedStatus.value
      };
      this.totalPayload = payload;
      this.loader = true;
      this.$store
        .dispatch('template/awsBackupReport', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            this.awsBackupReportData = result;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRequestStatus() {
      this.loader = true;
      this.$store
        .dispatch('template/getLOVBySetType', 'REQUEST_STATUS')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });

            this.statusType = getValueAndText;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearFilters() {
      this.requestId = null;
      this.reqName = null;
      this.moduleName = null;
      this.tableName = null;
      this.backupDate = null;
      this.startDate = null;
      this.endDate = null;
      this.selectedStatus = {
        value: null,
        text: null
      };
      this.awsBackupReport();
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
